<template>
  <div class="page">
    <Navbar title="新增荣誉" type="SMP" />
    <van-form @submit="submit" class="form">
      <van-cell-group>
        <van-field
          v-model="honor.name"
          label="名称"
          placeholder="请输入荣誉名称"
          show-word-limit
          :rules="[{ required: true, message: '请输入荣誉名称' }]"
        />
        <van-field
          v-model="honor.issueDate"
          name="时间"
          label="时间"
          @click="issueDateShow = true"
          placeholder="请选择时间"
          :rules="[{ required: true, message: '请选择获取时间' }]"
        />
        <van-popup :show="issueDateShow" position="bottom">
          <van-datetime-picker
            v-model="currentDate"
            type="year-month"
            title="选择年月"
            @confirm="timeConfirm"
            @cancel="issueDateShow = false"
            :min-date="minDate"
            :max-date="maxDate"
        /></van-popup>
      </van-cell-group>
      <van-row>
        <van-col offset="4" span="16" class="subbtn">
          <van-button
            icon="passed"
            size="small"
            color="#00CC99"
            class="btn"
            native-type="submit"
          >
            提交保存
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <van-popup :show="loadingShow" style="background-color: transparent"
      ><van-loading size="24px" :color="COLOR" vertical>加载中...</van-loading>
    </van-popup>
  </div>
</template>
<script>
import { Icon, Tag, Form, CellGroup, Field, Popup, DatetimePicker, Loading } from 'vant'
import Navbar from '../../common/Navbar.vue'
export default {
  components: {
    Navbar: Navbar,
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [Form.name]: Form,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [Loading.name]: Loading

  },
  data () {
    return {
      loadingShow: false,
      personCode: '',
      seq: '',
      honor: { name: '', issueDate: '' },
      issueDateShow: false,
      currentDate: new Date(),
      minDate: new Date(1980, 0, 1),
      maxDate: new Date()
    }
  },
  mounted () {
    var query = this.$route.query
    this.personCode = query.personCode
    this.seq = query.seq
    this.retrieveHonor()
  },
  methods: {
    timeConfirm (time) {
      console.log(time)
      var year = time.getFullYear()
      var month = time.getMonth() + 1
      var t = year + '-' + (month < 10 ? '0' + month : month)
      this.honor.issueDate = t
      this.issueDateShow = false
    },
    async retrieveHonor () {
      var pd = { personCode: this.personCode, key: 'PSN_HNR_ITEM', type: 'DTL', seq: this.seq }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttr', this.$qs.stringify(pd))
      if (res.status === '200') {
        var content = JSON.parse(res.data.content)
        this.honor.name = content.name
        this.honor.issueDate = content.issueDate
      }
    },
    async submit () {
      var attrs = [
        { key: 'PSN_HNR_ITEM', content: JSON.stringify(this.honor), type: 'DTL', seq: this.seq }
      ]
      var pd = { personCode: this.personCode, attrs: JSON.stringify(attrs) }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/editPersonAttr', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.$router.go(-1)
      } else {
        this.$dialog.alert({
          title: '提示信息',
          message: '编辑失败:' + res.msg
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.form {
  padding: 0px 5px;
  .btn {
    margin-top: 10px;
  }
}
</style>
